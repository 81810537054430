.label {
    width: 250px;
    padding-left: 1rem;
    padding-bottom: 0.5rem;
  }
  
  .combobox {
    font-weight: normal;
    height: 2.5rem;
    border-radius: 0.375rem;
    border-style: none;
    background-color: hsl(204 20% 100%);
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--gray-12);
    outline-width: 1px;
    outline-offset: -1px;
    border: 1px solid var(--gray-6);
    outline-color: var(--focus-8);
    box-shadow: inset 0 0 0 var(--text-field-border-width) var(--gray-a7);
  }
  
  .combobox::placeholder {
    color: hsl(204 4% 0% / 0.6);
  }
  
  .combobox:hover {
    background-color: hsl(204 20% 99%);
  }
  
  .combobox[data-focus-visible] {
    outline-style: solid;
  }
  
  .combobox[data-active-item] {
    outline-width: 2px;
  }
  
  :is(.dark .combobox) {
    background-color: hsl(204 4% 8%);
    color: hsl(204 20% 100%);
    box-shadow:
      inset 0 0 0 1px rgba(255 255 255/0.15),
      inset 0 -1px 0 0 rgba(255 255 255/0.05),
      inset 0 2px 5px 0 rgba(0 0 0/0.15);
  }
  
  :is(.dark .combobox)::placeholder {
    color: var(--gray-a8);
  }
  
  :is(.dark .combobox:hover) {
    background-color: hsl(204 4% 6%);
  }
  
  .popover {
    position: relative;
    z-index: 50;
    display: flex;
    max-height: min(var(--popover-available-height, 300px), 300px);
    flex-direction: column;
    overflow: auto;
    overscroll-behavior: contain;
    border-radius: 0.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: hsl(204 20% 88%);
    background-color: hsl(204 20% 100%);
    padding: 0.5rem;
    color: var(--gray-12);
    outline: 2px solid transparent;
    outline-offset: 2px;
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  :is(.dark .popover) {
    border-color: hsl(204 4% 24%);
    background-color: hsl(204 4% 16%);
    color: hsl(204 20% 100%);
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 0.25),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  .combobox-item {
    display: flex;
    cursor: default;
    scroll-margin: 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    padding: 0.5rem;
    outline: none !important;
  }
  
  .combobox-item:hover {
    background-color: var(--focus-6);
  }
  
  .combobox-item[data-active-item] {
    background-color: var(--focus-8);
    color: hsl(204 20% 100%);
  }
  
  .combobox-item:active,
  .combobox-item[data-active] {
    padding-top: 9px;
    padding-bottom: 7px;
  }
  
  :is(.dark .combobox-item:hover) {
    background-color: var(--focus-6);
  }
  
  :is(.dark .combobox-item)[data-active-item] {
    background-color: var(--focus-8);
  }
  
  .no-results {
    gap: 0.5rem;
    padding: 0.5rem;
  }
  